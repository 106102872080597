<template>
  <v-dialog v-bind="bind.dialog" v-on="on.dialog">
    <v-card>
      <v-card-title primary-title>
        {{ bind.title }}
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.close" v-on="on.close">
          <v-icon>{{ icons.close }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form :ref="formRef">
          <v-text-field
            v-model="form.codigo"
            name="codigo"
            label="Código"
            placeholder="Escriba el código..."
            :rules="[(v) => !!v || 'El código codig es requerido.']"
          ></v-text-field>

          <v-text-field
            v-model="form.descripcion"
            name="descripcion"
            label="Color"
            placeholder="Escriba nombre del color de vehículo..."
            id="id_color"
            :rules="[(v) => !!v || 'El campo descripción es requerido.']"
          >
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-bind="bind.submit" v-on="on.submit">
          {{ labels.submit }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FormMixin from "@/Mixins/SetFormMixin.js";

export default {
  mixins: [FormMixin],
  data() {
    return {
      defaultTitle: "Formulario de Colores para Vehículos",
      form: {
        codigo: "",
        descripcion: "",
      },
    };
  },
};
</script>
